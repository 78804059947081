






























































































import Vue from 'vue';
import request from '../model/request';
import { mdiWechat,mdiEmail,mdiQqchat,mdiGithub } from "@mdi/js";
interface tagitem{
  id:string,
  name:string,
  color:string
}
interface iData{

  carouselList:any[],
  icons:any,

  picIndex:number,
  colors:string[],
  

  tags?:any,
  articles?:any,
  profile:any,

  ArticleCount:number,
  TagCount:number
}
export default Vue.extend({
  name: 'RightTemplate',
  components: {
  },
  data():iData{
    return{

      icons: {
        mdiWechat,
        mdiEmail,
        mdiQqchat,
        mdiGithub
      },
      picIndex: 0,
      colors: [
        'primary',
        'secondary',
        
        'red',
        'orange',
        'yellow darken-2',
      ],
      
      tags:[],
      articles:[],
      carouselList:[require('@/assets/Img/side_02.jpg'),require('@/assets/Img/side_01.jpg'),require('@/assets/Img/side_03.jpg')],
      profile:require("@/assets/Img/profile.jpg"),

      ArticleCount:0,
      TagCount:0,
    }
  },
  
  methods: {

      getTags(){
        request
          .get('/Tags')
          .then(response=> {
            this.tags=response.data;

          })
      },
      getCount(){
        request
          .get('/Count')
          .then(response=> {
            if(response.data){
              this.ArticleCount=response.data.articleCount;
              this.TagCount=response.data.tagCount;
            }
          })
      },
      ParentSetTheme(){
          this.$vuetify.theme.dark=!this.$vuetify.theme.dark;
          if(this.$parent.setTheme){
              this.$parent.setTheme();
          }
      }
  },
  created() {

    this.getTags();
    this.getCount();
  },

  
  
})
