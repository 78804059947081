











































































































import Vue from 'vue';

export default Vue.extend({
  name: 'App',

  components: {

  },

  data: () => ({
    drawer:false,
    group: null
  }),
  watch: {
      group () {
        this.drawer = false
      },
    },
  created(){
    this.$vuetify.theme.dark=true;
  }
});
