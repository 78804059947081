






import Vue from 'vue';
// import Waterfall from 'vue-waterfall/lib/waterfall';
// import WaterfallSlot from 'vue-waterfall/lib/waterfall-slot';

export default Vue.extend({
  name: 'About',
  components: {

  },
  
  data(){
    return{
     
    }
  },
  
  methods: {

  },


  
  
})
