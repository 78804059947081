






































import Vue from 'vue';
import {marked} from 'marked';
import request from '../model/request';
import { mdiWechat,mdiEmail,mdiQqchat,mdiGithub } from "@mdi/js";
import showdown from 'showdown';
import ComponentA from './RightTemplate.vue';

export default Vue.extend({
  name: 'Blog',
  components: {
    // Waterfall,
    // WaterfallSlot
    ComponentA
  },
  data(){
    return{
      body_str:"",
      carouselList:[require('@/assets/Img/side_02.jpg'),require('@/assets/Img/side_01.jpg'),require('@/assets/Img/side_03.jpg')],
      profile:require("@/assets/Img/profile.jpg"),
      tags:[],
      icons: {
        mdiWechat,
        mdiEmail,
        mdiQqchat,
        mdiGithub
      },
      picIndex: 0,
      topBtn:false,
      markdown:new showdown.Converter()
    }
  },
  methods: {
      getArticle(){
        var query =this.$route.query;
        if(!query.id)return;

        request
          .get('/Article?id='+query.id)
          .then(response=> {
            if(response.data){
              this.body_str=response.data.body;
             // console.log(this.body_str);
            }

          })
      },
      setTheme(){
        if(this.markdown.getOption("theme")!="dark"){
          this.markdown.setOption("theme","dark");
        }else{
          this.markdown.setOption("theme","light");
        }
        
        var temp =this.body_str;
        this.body_str='';
        this.body_str=temp;
      }

  },
  computed: {
      markdownToHtml():string {
        return this.markdown.makeHtml(this.body_str);
        //return marked(this.body_str);
      }
  },
  created() {
    if(this.$vuetify.theme.dark){
      this.markdown.setOption("theme","dark");
    }else{
      this.markdown.setOption("theme","light");
    }
    this.getArticle();
    
  }
  
  
})
