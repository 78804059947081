






















































import Vue from 'vue';
import request from '../model/request';
import { mdiWechat,mdiEmail,mdiQqchat,mdiGithub } from "@mdi/js";
import ComponentA from './RightTemplate.vue'
// import Waterfall from 'vue-waterfall/lib/waterfall';
// import WaterfallSlot from 'vue-waterfall/lib/waterfall-slot';
interface tagitem{
  id:string,
  name:string,
  color:string
}
interface iData{
  line?:string,
  align?:string,
  items:any[],
  isBusy?:boolean,
  lastIndex:number,
  grow:any[],
  topBtn:boolean,

  typeList:any[],
  tagList:any[],
  carouselList:any[],
  icons:any,

  picIndex:number,
  colors:string[],
  

  tags?:any,
  articles?:any,
  profile:any
}
export default Vue.extend({
  name: 'Home',
  components: {
    // Waterfall,
    // WaterfallSlot
    ComponentA
  },
  
  data():iData{
    return{
      line: 'h',
      align: 'center',
      items:[],
      isBusy:false,
      lastIndex:0,
      grow: [4, 2, 3],
      topBtn:false,

      typeList:[{name:'.net',num:1},{name:'javascript',num:2},{name:'docker',num:3}],
      tagList:[{name:'数据结构'},{name:'算法'},{name:'微服务'},{name:'ELK'},{name:'Docker'},{name:'.Net Core'},{name:'node.js'}],

      icons: {
        mdiWechat,
        mdiEmail,
        mdiQqchat,
        mdiGithub
      },
      picIndex: 0,
      colors: [
        'primary',
        'secondary',
        
        'red',
        'orange',
        'yellow darken-2',
      ],
      
      tags:[],
      articles:[],
      carouselList:[require('@/assets/Img/side_02.jpg'),require('@/assets/Img/side_01.jpg'),require('@/assets/Img/side_03.jpg')],
      profile:require("@/assets/Img/profile.jpg"),
    }
  },
  
  methods: {

    addItems () {
      if (!this.isBusy && this.items.length < 500) {
        this.isBusy = true
      }
    },
    shuffle() {
      this.items.sort(function () {
        return Math.random() - 0.5
      })
    },
    reflowed(){
      this.isBusy = false
    },
     generateRandomItems (count:number){
        if(this.items.length>20)return;
        var items = [], i;
        for (i = 0; i < count; i++) {
          
          this.items.push({
            index: this.lastIndex++,
            style: {
              background: this.getRandomColor()
            },
            width: 150 + ~~(Math.random() * 50),
            height: 120 + ~~(Math.random() * 70)
          });
        }
      },
      getRandomColor () {
        var colors = [
          'rgba(21,174,103,.5)',
          'rgba(245,163,59,.5)',
          'rgba(255,230,135,.5)',
          'rgba(194,217,78,.5)',
          'rgba(195,123,177,.5)',
          'rgba(125,205,244,.5)'
        ]
        return colors[~~(Math.random() * colors.length)]
      },
      scrollEvent(){
      
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if(scrollTop==0){
          this.topBtn=false;
        }
        if(scrollTop>=window.innerHeight){
          this.topBtn=true;
        }
        if (scrollTop + window.innerHeight >= document.body.clientHeight) {
          
          this.generateRandomItems(5);
        }
      },
      topScrollTo(){
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        this.topBtn=false;
      },
      getArticles(){
        request
          .get('/Articles')
          .then(response=> {
            this.articles=response.data;

          })
      },
      jump(id:string){
        this.$router.push("/Blog?id="+id);
      }
  },
  created() {
    this.generateRandomItems(15);

    this.getArticles();

    window.addEventListener('scroll',  this.scrollEvent)
  },

  
  
})
